<template>
  <div class="flex flex-col text-gray-700 bg-white h-auto w-auto px-4 py-4 rounded-sm shadow">
    <div class="flex-1 flex-auto py-2 font-bold">
      <img class src="../assets/img/xcm_logo.png" alt="XCM Logo" />
    </div>
    <div class="flex flex-col flex-auto py-2">
      <div class="flex flex-row">
        <div class="flex flex-auto text-base font-normal">Download-ID: {{uuid}}</div>
        <div
          class="flex flex-auto text-base font-normal"
        >Datei/en: {{fileList.length}}, {{(fileSize/1024/1024).toFixed(2)}}MB</div>
      </div>
      <div class="flex flex-auto">
        <BarLoader class="flex flex-auto w-full mt-2" color="#bada55" :loading="loading" />
      </div>
    </div>
    <div
      v-for="file in fileList"
      :key="file.name"
      class="flex w-full flex-auto flex-row block py-1"
    >
      <div
        class="flex mr-2 rounded-sm flex px-2 rounded-sm bg-green-200 text-gray-800 hover:bg-green-400 hover:text-gray-900 focus:outline-none shadow-md hover:shadow-lg"
        v-on:click.prevent="downloadFile(file.name)"
      >
        <button class="focus:outline-none text-base font-normal">Download</button>
      </div>
      <div
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
        class="flex text-base font-normal px-2 py-1 rounded-sm"
      >{{(file.size / 1024/1024).toFixed(2)}}MB - {{decodeURI(file.name)}}</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import router from '../router';
export default {
  name: "download",
  components: {},
  data() {
    return {
      token: "",
      uuid: "",
      fileList: [],
      fileSize: 0,
      loading: true
    };
  },
  mounted() {
    this.uuid = this.$route.query.dl;
    this.token = this.$store.getters.token;
    this.getFileList();
  },
  methods: {
    getFileList: function() {
      let { token, uuid } = this;
      return new Promise((resolve, reject) => {
        axios({
          url: "/getfilelist",
          data: {},
          method: "GET",
          headers: {
            uuid: uuid,
            Authorization: token
          }
        }).then(
          response => {
            let x = 0;
            let fl = response.data;
            fl.forEach(element => {
              element.name = element.name.split("/")[1];
              element.prefix = element.name.split("/")[0];
              x = x + element.size;
            });
            this.fileList = fl;
            this.fileSize = x;
            this.loading = false;
            resolve(response);
          },
          error => {
            this.$toasted.success("nothing to see here...", {
              duration: 2000
            });
            reject(error);
          }
        );
      });
    },
    downloadFile: function(name) {
      let { token, uuid } = this;
      axios({
        url: "/getfile",
        data: {},
        method: "POST",
        headers: {
          uuid: uuid,
          Authorization: token,
          Name: name
        }
      }).then(
        response => {
          this.$toasted.info("Wird geladen: " + name, {
            duration: 4500
          });
          window.location = response.data.url;
        },
        error => {
          this.$toasted.error("oh snap, " + error.response.text + "...", {
            duration: 3500
          });
        }
      );
    }
  }
};
</script>